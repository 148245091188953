<template>
  <div>
    <a-card style="height: calc(80vh - 30px)">
      <div slot="title" style="height: 30px">
        <span>生产任务提醒</span>
      </div>

      <a-table
        :columns="columns"
        :data-source="items"
        size="small"
        :pagination="false"
        :loading="loading"
        :scroll="{ y: tableHeight }"
      >
        <template slot="image" slot-scope="value, record">
          <div>
            <!-- 首先检查数组是否存在且不为空 -->
            <div
              v-if="
                record.goods_image_items && record.goods_image_items.length > 0
              "
            >
              <!-- 接下来检查第一项是否为特殊值 'no_good' -->
              <div v-if="record.goods_image_items[0] === 'no_good'">
                未匹配到产品
              </div>
              <!-- 如果不是 'no_good'，则显示图片 -->
              <img
                v-else
                :src="record.goods_image_items[0].file"
                alt="Preview"
                @click="showImages(record.goods_image_items)"
                style="width: 50px; cursor: pointer"
              />
            </div>
            <!-- 如果数组不存在或为空，也显示文本 -->
            <div v-else>未上传图片</div>
          </div>
        </template>

        <div slot="date" slot-scope="value">
          {{ moment(value).format("YYYY-MM-DD") }}
        </div>

        <div slot="action" slot-scope="value, item">
          <a-button-group>
            <a-button type="link" @click="viewTask(item)">编辑</a-button>
            <a-button
              type="link"
              v-if="item.goods"
              @click="viewTaskProcess(item)"
              >生产进度</a-button
            >
          </a-button-group>
        </div>
      </a-table>
      <div style="text-align: center; margin-top: 16px">
        <a-pagination
          v-model="searchForm.page"
          :total="totalRows"
          :pageSize="perPage"
          show-less-items
          @change="changePage"
        />
      </div>

      <!-- 图片浏览模态框 -->
      <a-modal
        title="Image Gallery"
        :visible="isModalVisible"
        @cancel="handleCancel"
        width="60%"
        footer="null"
      >
        <a-carousel effect="fade" v-model="currentImageIndex">
          <div v-for="(img, index) in images" :key="index">
            <img
              :src="img.file"
              style="width: 100%; height: auto"
              :alt="img.name"
            />
          </div>
        </a-carousel>
      </a-modal>
    </a-card>
  </div>
</template>

<script>
import { productionTaskList } from "@/api/system";
import moment from "moment";

export default {
  name: "WarehouseTask",
  data() {
    return {
      moment,
      taskType: "purchase",
      items: [],
      perPage: 16,
      totalRows: 0,
      searchForm: {
        page: 1,
        page_size: 10,
        is_done: false,
        statues_fitter: true,
      },
      loading: false,

      statusList: [
        {
          id: 1,
          code: "Analysis",
          name: "分析",
        },
        {
          id: 2,
          code: "SamplePurchase",
          name: "样品采购",
        },
        {
          id: 3,
          code: "Purchase",
          name: "物料采购",
        },
        {
          id: 4,
          code: "InProduction",
          name: "生产中",
        },
        {
          id: 5,
          code: "Complete",
          name: "已完成",
        },
        {
          id: 6,
          code: "Shipped",
          name: "已发货",
        },
        {
          id: 7,
          code: "Cancel",
          name: "取消",
        },
      ],
      //  图片相关
      isModalVisible: false,
      images: [],
      currentImageIndex: 0,

      columns: [
        {
          title: "编号",
          dataIndex: "number",
          key: "number",
        },
        {
          title: "下单用户",
          dataIndex: "bm_user_name",
          key: "bm_user_name",
          // width:'60px'
        },
        {
          title: "所属店铺",
          dataIndex: "store_name",
          key: "store_name",
        },
        {
          title: "cs名称",
          dataIndex: "product_name",
          key: "product_name",
        },
        {
          title: "bm名称",
          dataIndex: "goods_name",
          key: "goods_name",
        },
        {
          title: "Image",
          key: "image",
          scopedSlots: { customRender: "image" },
        },
        {
          title: "订购数量",
          dataIndex: "quantity",
          key: "quantity",
        },
        {
          title: "单价",
          dataIndex: "sale_price",
          key: "sale_price",
        },
        {
          title: "下单金额",
          dataIndex: "total_amount",
          key: "total_amount",
          // customRender: (text) => {
          // return text
          // }
        },
        {
          title: "状态",
          dataIndex: "statues",
          key: "statues",
          customRender: (text) => {
            const statusItem = this.statusList.find(
              (status) => status.code === text
            );
            return statusItem ? statusItem.name : "未知状态";
          },
        },
        {
          title: "数量",
          dataIndex: "quantity_",
          key: "quantity_",
          customRender: (value, item) => {
            if (["InProduction", "Complete"].includes(item.statues)) {
              var quantity_ = item.actual_quantity;
            } else if (item.statues == "Shipped") {
              var quantity_ = item.shipment_quantity;
            } else {
              var quantity_ = item.quantity;
            }
            if (quantity_ == null) {
              var quantity_ = item.quantity;
            }
            return quantity_;
          },
        },
        {
          title: "期望交货时间",
          dataIndex: "handle_date",
          key: "handle_date",
          // width:'110px'
        },
        {
          title: "更新时间",
          dataIndex: "update_time",
          key: "update_time",
          // width:'110px'
        },
        {
          title: "创建时间",
          dataIndex: "create_time",
          key: "create_time",
        },
        {
          title: "用户备注",
          dataIndex: "remark",
          key: "remark",
        },
        {
          title: "操作",
          dataIndex: "action",
          scopedSlots: { customRender: "action" },
        },
      ],
      tableHeight: window.innerHeight / 1.5 - 150,
    };
  },
  methods: {
    initialize() {
      this.list();
    },
    list() {
      this.loading = true;
      // const excludedStatuses = ['Cancel', 'Complete'];
      productionTaskList(this.searchForm)
        .then((resp) => {
          // const filteredItems = resp.results.filter(item => !excludedStatuses.includes(item.statues));
          // this.items = filteredItems;
          this.items = resp.results;
          // 更新 totalRows 为过滤后的数组长度
          this.totalRows = resp.count;
          // this.totalRows = resp.results.length;
          // this.totalRows = filteredItems.length;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    viewTask(item) {
      // this.$router.push({ path: '/purchasing/purchase_record_detail', query: { id: item.id } });
      this.$router.push({
        path: "/production/production_record_detail",
        query: { search: item.id },
      });
    },

    viewTaskProcess(item) {
      this.$router.push({
        path: "/production/production_task_process",
        query: {
          search: item.id,
          goods: item.goods,
        },
      });
    },

    changeTask() {
      this.searchForm.page = 1;
      this.list();
    },
    changePage(value) {
      this.searchForm.page = value;
      this.list();
    },

    //图片相关的方法
    showImages(images) {
      this.images = images;
      this.isModalVisible = true;
    },
    handleCancel() {
      this.isModalVisible = false;
    },
  },
  mounted() {
    this.initialize();
  },
};
</script>

<style scoped>
</style>